import { useTranslation } from 'next-i18next'
import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

export default function PluginTooltip ({ compatibilities }) {
  const { t } = useTranslation(['common', 'applications', 'plugins'])
  const applicationLoaded = useSelector(state => state.app.hasLoaded)
  const uniqueCompatibilities = useMemo(() => new Set(compatibilities.map(compatibility => compatibility.software)))
  const title = useMemo(() => {
    let myTitle = '<ul class="list-unstyled p-2 w-100">'

    uniqueCompatibilities
      .forEach(compatibility => {
        myTitle += `<li class="d-flex justify-content-start align-items-center fs-6 text-start"><img class="me-2" src="/images/icons/check.png" alt="" />${t(compatibility, { ns: 'applications' })}</li>`
      })

    return `${myTitle}</ul>`
  })

  useEffect(() => {
    if (!applicationLoaded) {
      return
    }

    document
      .querySelectorAll('[data-bs-toggle="tooltip"]')
      .forEach(tooltipTriggerEl => new window.bootstrap.Tooltip(tooltipTriggerEl))
  }, [applicationLoaded])

  return (
    <span
      className="p-0 ms-1 fs-6"
      data-bs-toggle="tooltip"
      data-bs-html="true"
      {...uniqueCompatibilities.size > 0 && { 'data-bs-title': title } }
    >
      { t('applications', { ns: 'plugins', count: uniqueCompatibilities.size }) }
    </span>
  )
}
