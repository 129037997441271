export default function Star ({ width = 21, height = 21, color = '#D9D9D9', className = '', onClick }) {
  return (
    <div className={`${className}`}
      onClick={() => onClick()}
    >
      <svg width="1em"
        height="auto"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M11.2139 1.90891L13.5239 6.57931C13.5809 6.69545 13.6654 6.79594 13.7701 6.87205C13.8747 6.94816 13.9964 6.99761 14.1245 7.01611L19.2674 7.77001C19.4146 7.79122 19.5529 7.85324 19.6666 7.94905C19.7803 8.04485 19.865 8.17061 19.9109 8.31206C19.9568 8.45351 19.9621 8.60499 19.9263 8.74933C19.8906 8.89367 19.815 9.0251 19.7084 9.12871L15.9809 12.7617C15.8886 12.8521 15.8196 12.9635 15.7797 13.0864C15.7398 13.2093 15.7303 13.34 15.752 13.4673L16.6319 18.6018C16.6575 18.7486 16.6414 18.8996 16.5855 19.0376C16.5295 19.1757 16.436 19.2953 16.3154 19.3828C16.1948 19.4704 16.0521 19.5223 15.9035 19.5327C15.7549 19.5431 15.6064 19.5116 15.4748 19.4418L10.8716 17.01C10.7568 16.95 10.6293 16.9187 10.4999 16.9187C10.3704 16.9187 10.2429 16.95 10.1282 17.01L5.52496 19.4418C5.39336 19.5116 5.24481 19.5431 5.09619 19.5327C4.94758 19.5223 4.80488 19.4704 4.68432 19.3828C4.56376 19.2953 4.47018 19.1757 4.41424 19.0376C4.35829 18.8996 4.34222 18.7486 4.36786 18.6018L5.24986 13.4673C5.2715 13.34 5.26198 13.2093 5.22212 13.0864C5.18226 12.9635 5.11324 12.8521 5.02096 12.7617L1.29136 9.12451C1.18591 9.02073 1.11147 8.88962 1.0764 8.74589C1.04133 8.60216 1.04701 8.4515 1.09281 8.31082C1.13861 8.17014 1.22272 8.04502 1.33569 7.94948C1.44866 7.85395 1.58603 7.7918 1.73236 7.77001L6.88156 7.02031C7.00964 7.00181 7.13127 6.95236 7.23593 6.87625C7.34058 6.80014 7.4251 6.69965 7.48216 6.58351L9.78586 1.90891C9.85152 1.77558 9.95321 1.6633 10.0794 1.58479C10.2056 1.50627 10.3512 1.46466 10.4999 1.46466C10.6485 1.46466 10.7941 1.50627 10.9203 1.58479C11.0465 1.6633 11.1482 1.77558 11.2139 1.90891Z"
          fill={color}
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}
