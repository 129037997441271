import Image from 'next/future/image'
import PluginTooltip from './PluginTooltip'
import Link from 'next/link'
import Star from '../icons/Star'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { useCountryTaxRate } from '../../hooks/useCountryTaxRate'
import useCloudPrice from '../../hooks/useCloudPrice'

export default function PluginBigLogo ({
  plugin,
  className = 'col-lg-4 col-12 plugincard',
  hideDescription = false
}) {
  const { t } = useTranslation(['home', 'categories', 'plugins', 'common'])
  const { cloudPricePeriod } = useCloudPrice(plugin)
  const router = useRouter()

  const pluginIntro = useMemo(() => {
    const language = router.locale.toUpperCase()
    const description = plugin.descriptions.find(description => description.language === language)

    if (!description) return plugin.descriptions[0].intro

    return description.intro
  })

  const { initializeUserCountry, taxRate } = useCountryTaxRate(plugin)

  useEffect(() => {
    initializeUserCountry()
  }, [])

  return (
    <div
      className={`${className}`}
      key={plugin.id}
    >
      <Link href={`/plugins/${plugin.slug}`}>
        <a
          className="border border-light h-100 d-flex flex-column rounded-3 overflow-hidden cursor-pointer text-decoration-none text-default"
        >
          <div className="row">
            <div className="d-flex align-items-center justify-content-center ">
              <div
                className="d-flex align-items-center justify-content-center p-5 cursor-pointer bg-white w-100"
                style={{ minHeight: '250px' }}
              >
                {plugin.logo && (
                  <Image
                    alt=""
                    className="w-auto h-auto d-block"
                    src={plugin.logo.url}
                    width={370}
                    height={250}
                  />
                )}
                {
                  !plugin.logo && t('plugins.no_image')
                }
              </div>
            </div>
          </div>
          <div className="bg-light p-4 flex-grow-1 d-flex flex-column">
            <div className="d-flex flex-wrap fs-6 gap-2 mb-2">
              <span className="col-auto d-flex">
                {
                  plugin.rating_total > 0 && (
                    <span className="d-flex align-items-center">
                      <Star
                        color="#1A7DFF"
                        className="d-flex"
                      />
                      <span
                        className="text-primary ms-1 me-2"
                      >{Math.round(plugin.rating_total * 10) / 10}</span> ({plugin.rating_count})
                    </span>
                  )
                }
                {
                  !plugin.rating_total && (
                    <span>{t('no_rating', { ns: 'plugins' })}</span>
                  )
                }
              </span>
              <span className="col-auto">
                ·
              </span>
              <span className="col-auto d-inline-flex align-items-center">
                <PluginTooltip compatibilities={plugin.compatibilities || []} />
              </span>
            </div>
            <div className="d-flex flex-column align-items-start flex-grow-1">
              <Link href={`/plugins/${plugin.slug}`}>
                <span className="text-dark text-decoration-none cursor-pointer">
                  <h3>{plugin.name}</h3>
                </span>
              </Link>
              {
                (plugin.descriptions.length > 0 && pluginIntro && !hideDescription) && (
                  <p className="mb-0 flex-grow-1">
                    {pluginIntro}
                  </p>
                )
              }
              {
                (!plugin.is_free && !plugin.is_cloud_product && !plugin.is_on_request_only) && (
                  <div>
                    <Link href={`/plugins/${plugin.slug}`}>
                      <button className="btn btn-primary btn-sm w-auto mt-4 mb-2">
                        {
                          t(
                            'price',
                            {
                              ns: 'plugins',
                              price: plugin.starting_price ? plugin.starting_price / 100 : plugin.starting_price
                            }
                          )
                        }
                      </button>
                    </Link>
                    {taxRate?.getTaxRate
                      ? (<span className="row fs-6 mx-1 mb-2">
                        {
                          t(
                            'price',
                            {
                              ns: 'plugins',
                              price: plugin.starting_price ? (plugin.starting_price + plugin.starting_price * taxRate?.getTaxRate / 100) / 100 : plugin.starting_price
                            }
                          )
                        }
                        {
                          t('detail.cart.w_tax', { ns: 'plugins' })
                        }
                      </span>)
                      : ''}
                  </div>
                )
              }
              {
                plugin.is_cloud_product && (
                  <div className="col-12 mb-3">
                    <Link
                      href={`/seller/${plugin.company.slug}?contactSubject=${encodeURIComponent(plugin.name)}#sellerContactForm`}
                      target="_blank"
                    >
                      <span className="btn btn-primary w-100 position-relative">
                        <span>
                          {t('price_with_period', {
                            ns: 'plugins',
                            price: plugin.cloud_starting_price_net / 100,
                            period: cloudPricePeriod
                          })}
                          <br />
                          {t('cloud_product_contact_seller', { ns: 'plugins' })}
                        </span>
                      </span>
                    </Link>
                  </div>
                )
              }
              {
                plugin.is_on_request_only && (
                  <div className="col-12 mb-3">
                    <Link
                      href={`/seller/${plugin.company.slug}?contactSubject=${encodeURIComponent(plugin.name)}#sellerContactForm`}
                      target="_blank"
                    >
                      <span
                        className="btn btn-primary w-100 position-relative"
                      >
                        <span>
                          {t('one_time_payment_contact_seller', {
                            ns: 'plugins',
                            price: plugin.starting_price / 100
                          })}
                        </span>
                      </span>
                    </Link>
                  </div>
                )
              }
              {
                plugin.is_free && (
                  <button className="btn btn-primary btn-sm w-auto my-4">
                    {
                      t('is_free', { ns: 'plugins' })
                    }
                  </button>
                )
              }
              <div className="row g-2">
                {
                  plugin.categories.map(category => (
                    <div
                      key={category.id}
                      className="col-auto"
                    >
                      <div
                        className="badge text-primary rounded-pill"
                        style={{ backgroundColor: 'rgba(107, 113, 119, 0.1)' }}
                      >
                        {t(category.category, { ns: 'categories' })}
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}
